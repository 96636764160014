import React, { useEffect } from 'react';
import { FAQ } from './FAQ';
import { Question } from './Question';
import { Answer } from './Answer';
import { HttpMethod, useFetch } from 'hooks/use-fetch';
import { ResponseProps } from '../FAQ';

interface ListProps {
	search?: string;
	tag?: string;
}

export function List({ search, tag }: ListProps): JSX.Element {
	const { response } = useFetch<ResponseProps[]>({
		url: '/api/help/?query=' + search,
		method: HttpMethod.GET,
	});

	const url = new URL(window.location.href);
	const id = url.searchParams.get('id');

	useEffect(() => {
		if (id) {
			document
				.querySelector('.FAQs--Container')
				.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	if (!response) return <></>;
	return (
		<>
			{response
				.filter(faq => {
					if (id !== null) return +id === faq.id;
					if (tag === '') return true;
					if (faq.tags.some(t => t.name === tag)) return true;
					return false;
				})
				.map((faq, key) => {
					return (
						<FAQ key={key} {...faq}>
							<details open={id !== null}>
								<summary>
									<Question />
								</summary>
								<Answer />
							</details>
						</FAQ>
					);
				})}
		</>
	);
}
