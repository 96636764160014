import React, { Dispatch, ReactNode } from 'react';

interface QuickButtonProps {
	setTag: Dispatch<string>;
	tag: string;
	activeTag: string;
	children: ReactNode | ReactNode[];
	scrollTo: any;
}

export function QuickButton({
	setTag,
	tag,
	activeTag,
	children,
	scrollTo,
}: QuickButtonProps): JSX.Element {
	function handleClick(): void {
		const url = new URL(window.location.href);
		url.searchParams.delete('type');
		url.searchParams.delete('id');

		window.history.replaceState({}, '', url);
		if (tag === activeTag) {
			setTag('');
		} else {
			setTag(tag);
			scrollTo.current.scrollIntoView({ behavior: 'smooth' });
		}
	}
	return (
		<button
			className={[
				'QuickButton',
				tag === activeTag ? 'QuickButton__Active' : '',
			].join(' ')}
			onClick={handleClick}
		>
			{children}
		</button>
	);
}
