import React, { ReactNode, createContext } from 'react';
import { ResponseProps } from '../FAQ';

interface FAQProps extends ResponseProps {
	children: ReactNode;
}

export const FAQContext = createContext<{
	question: string;
	answer: string;
}>(null);

export function FAQ({ question, answer, children }: FAQProps): JSX.Element {
	return (
		<FAQContext.Provider value={{ question, answer }}>
			<div className="FAQ">{children}</div>
		</FAQContext.Provider>
	);
}
