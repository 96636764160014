import React, { useContext } from 'react';
import { FAQContext } from './FAQ';

export function Answer(): JSX.Element {
	const { answer } = useContext(FAQContext);
	return (
		<div className="Answer">
			<div dangerouslySetInnerHTML={{ __html: answer }} />
		</div>
	);
}
