import React from 'react';
import { OpeningTimes } from 'components/Common/OpeningTimes/OpeningTimes';
import { SupportPhoneNumber } from 'components/Common/ContactInformation';

const ContactUs = () => {
	return (
		<>
			<div className="o-wrapper">
				<div className="o-flex-container u-py--giant u-d--flex u-jc--space-between u-ai--center u-px--small">
					<a
						href="/account"
						className="o-flex-item u-grid--12 u-grid--6--bp-medium mpd-help-info-cards__card u-p--medium--bp-medium"
					>
						<div className="u-bgc--inverted u-d--flex">
							<div className="mpd-help-info-cards__image u-d--flex">
								<img
									alt="Customer looking down at a phone held in their hands"
									aria-hidden={true}
									src="/images/order-track.jpg"
									style={{
										maxWidth: 'none',
										maxHeight: 'none',
									}}
								/>
							</div>
							<div className="u-p--medium">
								<h3 className="u-mb--tiny">
									After You&apos;ve Ordered
								</h3>
								<p className="u-fw--300 u-c--primary-text">
									Check your order details and get an update
									on your delivery
								</p>
							</div>
						</div>
					</a>
					<a
						className="o-flex-item u-grid--12 u-grid--6--bp-medium mpd-help-info-cards__card u-p--medium--bp-medium"
						href="/deals/offers"
					>
						<div className="u-bgc--inverted u-d--flex">
							<div
								className="mpd-help-info-cards__image u-d--flex"
								style={{ backgroundColor: 'rgb(152, 193, 30)' }}
							>
								<img
									alt="latest promotions icon"
									aria-hidden={true}
									src="/images/latest-promotion-icon.svg"
									style={{
										maxWidth: 'none',
										maxHeight: 'none',
									}}
								/>
							</div>
							<div className="u-p--medium">
								<h3 className="u-mb--tiny">
									Latest Promotions
								</h3>
								<p className="u-fw--300 u-c--primary-text">
									Looking for a great deal before you buy,
									view our promotions page for the latest
									offers
								</p>
							</div>
						</div>
					</a>
				</div>
			</div>
			<div style={{ backgroundColor: '#fff' }}>
				<div className="o-wrapper">
					<div className="o-flex-container mpd-help-contact-us u-mx--small u-my--xlarge u-pt--medium u-pb--xlarge u-px--small">
						<div className="o-flex-item u-grid--12">
							<h2 className="u-fz--display-large">Contact us</h2>
						</div>
						<div className="o-flex-item u-grid--12 u-grid--6--bp-medium mpd-help-contact-us__section u-d--flex u-fxd--column u-jc--space-between">
							<p className="u-fw--600">Send us an email</p>
							<p style={{ fontSize: '12px' }}>
								We promise to get back to you within 48 hours
								<br />
								<a href="mailto:webshop@mobilephonesdirect.co.uk">
									webshop@mobilephonesdirect.co.uk
								</a>
							</p>
							<br />
							<p className="u-fz--base u-fw--600">
								To allow us to deal with your query as soon as
								possible, please have the following ready before
								calling or emailing us:
							</p>
							<ul className="u-fz--base u-fw--600">
								<li>Your order number if you have it</li>
								<li>Your date of birth</li>
								<li>Your post code</li>
								<li>The first line of your address</li>
								<li>
									The email address used to place your order
								</li>
							</ul>
						</div>
						<div className="o-flex-item u-grid--12 u-grid--6--bp-medium mpd-help-contact-us__section">
							<p className="u-fw--600 u-mb--tiny">
								Call our Support Team
							</p>
							<p className="u-mb--tiny">
								Call us on{' '}
								<SupportPhoneNumber className="u-fw--600" />
							</p>
							<div
								style={{
									fontSize: '12px',
									marginBottom: '-18px',
								}}
							>
								<OpeningTimes shortenDay={false}>
									{({ open, close, day, toDay }, index) => (
										<div key={index}>
											<b>
												{day} {toDay && `- ${toDay}`}
											</b>{' '}
											<span>
												{open} {close && `- ${close}`}
											</span>
										</div>
									)}
								</OpeningTimes>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUs;
