import React, { FormEvent, useRef, useState } from 'react';
import { QuickButton } from './Partials/QuickButton';
import { List } from './Partials/List';
import './FAQ.scss';
import { useDelayState } from 'hooks/use-delay-state';
import ContactUs from './Partials/ContactUs';

interface ResponseTagProps {
	id: number;
	name: string;
}

export interface ResponseProps {
	id: number;
	question: string;
	answer: string;
	featured: boolean;
	tags: ResponseTagProps[];
}

export function FAQs(): JSX.Element {
	const searchRef = useRef<HTMLInputElement>();
	const resultsRef = useRef<HTMLDivElement>();
	const [search, setSearch] = useDelayState<string>('');
	const [tag, setTag] = useState('');

	function handleSearch(): void {
		const url = new URL(window.location.href);
		url.searchParams.delete('type');
		url.searchParams.delete('id');

		window.history.replaceState({}, '', url);
		setSearch(searchRef.current.value);
	}
	function handleSubmit(e: FormEvent): void {
		e.preventDefault();
		resultsRef.current.scrollIntoView({ behavior: 'smooth' });
	}

	return (
		<div>
			<div className="FAQs">
				<div className="FAQs--Hero">
					<div className="FAQs--Hero--Container">
						<h1>Help &amp; Advice</h1>
						<p>
							We&apos;re here to help, no questions left
							unanswered!
						</p>
						<form onSubmit={handleSubmit}>
							<input
								type="text"
								placeholder="Have a question? Search below or enter a keyword here..."
								ref={searchRef}
								onChange={handleSearch}
								defaultValue={search}
							/>
						</form>
					</div>
				</div>
				<div className="FAQs--Quick">
					<div className="FAQs--Quick--Container">
						<QuickButton
							tag="ordering"
							setTag={setTag}
							activeTag={tag}
							scrollTo={resultsRef}
						>
							<span className="ico ico-basket ico-6x" />
							<div className="QuickButton--Text">
								<h3>Placing Your Order</h3>
							</div>
						</QuickButton>
						<QuickButton
							tag="after-order"
							setTag={setTag}
							activeTag={tag}
							scrollTo={resultsRef}
						>
							<span className="ico ico-document ico-6x" />
							<div className="QuickButton--Text">
								<h3>After Your Order</h3>
							</div>
						</QuickButton>
						<QuickButton
							tag="delivery"
							setTag={setTag}
							activeTag={tag}
							scrollTo={resultsRef}
						>
							<span className="ico ico-delivery ico-6x" />
							<div className="QuickButton--Text">
								<h3>After Your Delivery</h3>
							</div>
						</QuickButton>
						<QuickButton
							tag="top"
							setTag={setTag}
							activeTag={tag}
							scrollTo={resultsRef}
						>
							<span className="ico ico-product-info ico-6x" />
							<div className="QuickButton--Text">
								<h3>Top Queries</h3>
							</div>
						</QuickButton>
					</div>
				</div>
				<div className="FAQs--Container" ref={resultsRef}>
					<h1>Frequently Asked Questions</h1>
					<List search={search} tag={tag} />
				</div>
			</div>
			<ContactUs />
		</div>
	);
}
